import { useMutation } from "@apollo/client";
import styled from "@emotion/native";
import { useNavigation, useRoute, RouteProp } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";
import colors from "../colors";
import {
  Button,
  ErrorMessage,
  ExitModal,
  RewardCarousel,
  SafeArea,
  Text,
} from "../components";
import { cards, diplomas } from "../config";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { StackParamList } from "../types";

const SelectRewardsScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "SelectRewards">>();
  const { adventure, key, playersNumber, unlockNumber } = params;
  const rewards = adventure === "initial" ? cards : diplomas;
  const [error, setError] = useState<boolean>(false);
  const [exitVisible, setExitVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<number[]>([]);

  const imageHeight =
    adventure === "initial" ? (isDesktop ? 406 : 232) : isDesktop ? 324 : 200;
  const imageWidth =
    adventure === "initial" ? (isDesktop ? 301 : 172) : isDesktop ? 486 : 300;

  const thumbnailHeight =
    adventure === "initial" ? (isDesktop ? 90 : 72) : isDesktop ? 90 : 72;
  const thumbnailWidth =
    adventure === "initial" ? (isDesktop ? 65 : 52) : isDesktop ? 125 : 100;

  const addReward = (reward: number) => {
    const newSelected = [...selected];
    newSelected.length < playersNumber && newSelected.push(reward);
    setSelected(newSelected);
  };

  const onSubmit = async () => {
    navigation.navigate("ShowRewards", {
      adventure,
      playersNumber,
      selected,
    });
  };

  const removeReward = (index: number) => {
    const newSelected = [...selected];
    newSelected.splice(index, 1);
    setSelected(newSelected);
  };

  return (
    <SafeArea>
      <Container>
        <TitleContent>
          <Title>
            {t(
              `select-rewards.text-choose.${
                playersNumber > 1 ? "plural" : "singular"
              }`
            )}
          </Title>
          <Title bold>
            {t(`select-rewards.text-reward.${adventure}`)}
            {/* {playersNumber > 1 && t("select-rewards.text-per-person")} */}
          </Title>
          {adventure === "initial" && (
            <Title>
              {t(
                `select-rewards.text-skill.${
                  playersNumber > 1 ? "plural" : "singular"
                }`
              )}
            </Title>
          )}
        </TitleContent>
        <RewardCarousel
          height={imageHeight}
          width={imageWidth}
          rewards={rewards.slice(0, unlockNumber)}
          setReward={(index: number) => addReward(index)}
          slides={adventure === "initial" ? 5 : 3}
        />
        <Selected>
          {Array(playersNumber)
            .fill(0)
            .map((_player, index: number) =>
              selected[index] !== undefined ? (
                <RewardSelected
                  key={index}
                  onPress={() => removeReward(index)}
                  style={{
                    backgroundColor: rewards[selected[index]].color,
                    elevation: 10,
                    height: thumbnailHeight,
                    width: thumbnailWidth,
                  }}
                >
                  <Thumbnail source={rewards[selected[index]].thumbnail} />
                </RewardSelected>
              ) : (
                <Empty
                  key={index}
                  style={{
                    height: thumbnailHeight,
                    width: thumbnailWidth,
                  }}
                />
              )
            )}
        </Selected>
        <Button
          disabled={selected.length < playersNumber}
          iconName="arrow-small"
          onPress={onSubmit}
        >
          {t("general.continue-button")}
        </Button>
        <ErrorMessage visible={error} onClose={() => setError(false)} />
      </Container>
      <ExitModal
        plural={playersNumber > 1}
        visible={exitVisible}
        setVisible={setExitVisible}
      />
    </SafeArea>
  );
};

export default SelectRewardsScreen;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

const Empty = styled.View`
  border: 1px dashed ${colors.black};
  border-radius: 6px;
  margin: 0 5px;
`;

const RewardSelected = styled.TouchableOpacity`
  border: 2px solid ${colors.white};
  border-radius: 6px;
  margin: 0 5px;
`;

const Selected = styled.View`
  flex-direction: row;
  margin-top: 14px;
  margin-bottom: 30px;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
`;

const TitleContent = styled.View`
  flex-direction: row;
  margin-bottom: 30px;
`;

const Thumbnail = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: cover;
`;
