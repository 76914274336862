import {
  Anchor,
  EvaluationActivityType as ActivityType,
} from "@digicraft/types";
import * as TypesModule from "@digicraft/types";
import { ImageSourcePropType } from "react-native";

export { Anchor, ActivityType };

export type IActivity = TypesModule.IEvaluationActivity;
export type ICatchActivityContent = TypesModule.ICatchActivityContent;
export type ILabelActivityContent = TypesModule.ILabelActivityContent;
export type IMatchActivityContent = TypesModule.IMatchActivityContent;
export type IRightWrongImageActivityContent = TypesModule.IRightWrongImageActivityContent;
export type IRightWrongTextActivityContent = TypesModule.IRightWrongTextActivityContent;
export type ITestActivityContent = TypesModule.ITestActivityContent;
export type IOption = TypesModule.IOption;

export type Adventure = "initial" | "final";

export enum Age {
  sixToEight = "sixToEight",
  nineToTwelve = "nineToTwelve",
  elevenToFourteen = "elevenToFourteen",
}

export enum Avatar {
  Amira,
  Friend,
  Leo,
  Monster,
  Robot,
}

export interface IReward {
  color: string;
  source: ImageSourcePropType;
  thumbnail: ImageSourcePropType;
}

export interface IPlayer {
  avatar: Avatar;
  color: string;
  image: ImageSourcePropType;
  imageGray: ImageSourcePropType;
  name: string;
}

export type StackParamList = {
  Landing: undefined;
  EnterKey: undefined;
  Help: undefined;
  EnterAge: {
    key: string;
  };
  SelectPlayers: {
    age: Age;
    key: string;
  };
  Instructions: {
    activities: IActivity[];
    adventure: Adventure;
    players: IPlayer[];
    age: Age;
    key: string;
  };
  Beginning: {
    activities: IActivity[];
    adventure: Adventure;
    players: IPlayer[];
    age: Age;
    key: string;
  };
  Activities: {
    activities: IActivity[];
    adventure: Adventure;
    players: IPlayer[];
    age: Age;
    key: string;
  };
  Ending: {
    activities: IActivity[];
    activitiesNumber: number;
    adventure: Adventure;
    playersNumber: number;
    success: number;
    results: number[];
    age: Age;
    key: string;
  };
  UnlockRewards: {
    activitiesNumber: number;
    adventure: Adventure;
    playersNumber: number;
    success: number;
    key: string;
  };
  SelectRewards: {
    adventure: Adventure;
    playersNumber: number;
    unlockNumber: number;
    key: string;
  };
  ShowRewards: {
    adventure: Adventure;
    playersNumber: number;
    selected: number[];
  };
  Goodbye: {
    playersNumber: number;
  };
};
