import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC, useEffect, useState } from "react";
import colors from "../colors";
import {
  Button,
  ErrorMessage,
  Header,
  PlayerSelector,
  SafeArea,
  Text,
} from "../components";
import { useLanguage, useTranslate } from "../lib/translate";
import { IPlayer, StackParamList } from "../types";
import { START_ADVENTURE_QUERY } from "../queries";
import testActivities from "../test-activities";

const SelectPlayersScreen: FC = () => {
  const t = useTranslate();
  const { params } = useRoute<RouteProp<StackParamList, "SelectPlayers">>();
  const navigation = useNavigation();
  const [error, setError] = useState<boolean>(false);
  const [players, setPlayers] = useState<(IPlayer | undefined)[]>([
    undefined,
    undefined,
    undefined,
  ]);
  const [
    startAdventure,
    { loading, data, error: apolloError },
  ] = useLazyQuery(START_ADVENTURE_QUERY, { fetchPolicy: "network-only" });
  const [playerNumber, setPlayerNumber] = useState<number>(1);
  const language = useLanguage();

  const selectedPlayers: IPlayer[] = players.filter(
    (player?: IPlayer) => !!player
  ) as IPlayer[];

  useEffect(() => {
    if (!loading && data && data.startAdventure) {
      navigation.navigate("Instructions", {
        activities: data.startAdventure.activities.map((activity) => ({
          ...activity,
          content: JSON.parse(activity.content),
        })),
        adventure: data.startAdventure.adventure,
        players: selectedPlayers,
        age: params.age,
        key: params.key,
      });
    }
  }, [loading, data]);

  useEffect(() => setError(!!apolloError), [apolloError]);

  const addPlayer = () => setPlayerNumber(playerNumber + 1);

  const removePlayer = (index: number) => {
    setPlayerNumber(playerNumber - 1);
    const newPlayers: (IPlayer | undefined)[] = Object.assign([], players);
    newPlayers.splice(index, 1);
    setPlayers(newPlayers);
  };

  const setPlayer = (index: number, player?: IPlayer) => {
    const newPlayers: (IPlayer | undefined)[] = Object.assign([], players);
    if (player) {
      newPlayers[index] = player;
    } else {
      newPlayers[index] = undefined;
    }
    setPlayers(newPlayers);
  };

  const onSubmit = () => {
    /* Development only
    if (params.key.toUpperCase() === "TESTINITIAL") {
      navigation.navigate("Instructions", {
        activities: testActivities,
        adventure: "initial",
        players: selectedPlayers,
      });
      return;
    }
    if (params.key.toUpperCase() === "TESTFINAL") {
      navigation.navigate("Instructions", {
        activities: testActivities,
        adventure: "final",
        players: selectedPlayers,
      });
      return;
    }
    */
    startAdventure({
      variables: {
        age: params.age,
        code: params.key,
        language,
        numPlayers: selectedPlayers.length,
      },
    });
  };

  return (
    <SafeArea>
      <Header
        leftButton={{
          iconName: "arrow-circle",
          onPress: () => navigation.goBack(),
        }}
      />
      <Container>
        <Title>{t("select-players.text")}</Title>
        <ButtonGroup>
          {[0, 1, 2].map((index: number) => {
            const block = index + 1 > playerNumber;

            return (
              <Player key={index}>
                <StyledText>
                  {t(`select-players.number-player${block ? "-add" : ""}`, [
                    index + 1 + "",
                  ])}
                </StyledText>
                <PlayerSelector
                  block={block}
                  onAdd={addPlayer}
                  onDelete={index > 0 ? () => removePlayer(index) : undefined}
                  player={players[index]}
                  players={selectedPlayers}
                >
                  {({ children, getPlayer, notAllowed }) => (
                    <>
                      {children}
                      {!block &&
                        (!players[index] ? (
                          <StyledButton
                            disabled={notAllowed}
                            onPress={() => setPlayer(index, getPlayer())}
                          >
                            {t("select-players.select-button")}
                          </StyledButton>
                        ) : (
                          <StyledButton
                            tertiary
                            onPress={() => setPlayer(index, undefined)}
                          >
                            {t("select-players.change-button")}
                          </StyledButton>
                        ))}
                    </>
                  )}
                </PlayerSelector>
              </Player>
            );
          })}
        </ButtonGroup>
        <Button
          disabled={selectedPlayers.length < playerNumber}
          iconName="arrow-small"
          onPress={onSubmit}
        >
          {t("general.continue-button")}
        </Button>
        <ErrorMessage visible={error} onClose={() => setError(false)} />
      </Container>
    </SafeArea>
  );
};

export default SelectPlayersScreen;

const ButtonGroup = styled.View`
  flex-direction: row;
  margin: 30px 0;
`;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
  overflow: hidden;
`;

const Player = styled.View`
  margin: 0 32.5px;
`;

const StyledButton = styled(Button)`
  align-self: center;
`;

const StyledText = styled(Text)`
  align-self: center;
  margin-bottom: 10px;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-top: 10px;
`;
