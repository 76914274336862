import styled from "@emotion/native";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import React, { FC } from "react";
import colors from "../colors";
import { BulletPoint, Button, Header, SafeArea, Text } from "../components";
import { useBreakpoints } from "../lib/breakpoints";
import { useTranslate } from "../lib/translate";
import { View } from "react-native";
import { StackParamList } from "../types";

const InstructionsScreen: FC = () => {
  const t = useTranslate();
  const { isDesktop } = useBreakpoints();
  const navigation = useNavigation();
  const { params } = useRoute<RouteProp<StackParamList, "Instructions">>();
  const { adventure, players } = params;

  const diodiImage =
    adventure === "initial"
      ? require("../assets/images/diodi_card.png")
      : require("../assets/images/diodi_diploma.png");

  return (
    <SafeArea>
      <Header
        leftButton={{
          iconName: "arrow-circle",
          onPress: () => navigation.goBack(),
        }}
      />
      <Container>
        <Title>
          {t(
            `instructions.title.${adventure}.${
              players.length > 1 ? "plural" : "singular"
            }`
          )}
        </Title>
        <StyledText>
          {t(
            `instructions.text.${adventure}.${
              players.length > 1 ? "plural" : "singular"
            }`
          )}
        </StyledText>
        <ContentWrap style={{ height: isDesktop ? 280 : 229 }}>
          <ImageWrap>
            <DiodiImage
              style={{ width: isDesktop ? 280 : 229 }}
              source={diodiImage}
            />
          </ImageWrap>
          <View>
            {(Object.values(t(
              `instructions.steps.${adventure}.${
                players.length > 1 ? "plural" : "singular"
              }`
            ) as any) as string[]).map((step: string, index: number) => (
              <BulletPoint key={index} index={index}>
                {step}
              </BulletPoint>
            ))}
          </View>
        </ContentWrap>
        <Button
          iconName="arrow-small"
          onPress={() => navigation.navigate("Beginning", { ...params })}
        >
          {t("general.continue-button")}
        </Button>
      </Container>
    </SafeArea>
  );
};

export default InstructionsScreen;

const Container = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

const ContentWrap = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 30px 0;
`;

const DiodiImage = styled.Image`
  flex: 1;
  margin-right: 20px;
  resize-mode: contain;
  width: auto;
`;

const ImageWrap = styled.View`
  height: 100%;
`;

const StyledText = styled(Text)`
  text-align: center;
  width: 600px;
`;

const Title = styled(Text)`
  color: ${colors.red};
  font-size: 26px;
  margin-bottom: 20px;
`;
