import { useLazyQuery } from "@apollo/client";
import Constants from "expo-constants";
import React, { FC, useEffect, useState } from "react";
import { BackHandler } from "react-native";
import DialogModal from "./DialogModal";
import { useTranslate } from "../lib/translate";
import { APP_MIN_VERSION } from "../queries";

const AndroidVersionModal: FC = () => {
  const t = useTranslate();
  const [visible, setVisible] = useState<boolean>(false);
  const [appMinVersion, { loading, data }] = useLazyQuery(APP_MIN_VERSION);

  useEffect(() => {
    if (!loading && data && data.appMinVersion) {
      const appVersion = Constants?.manifest?.android?.versionCode;
      appVersion &&
        Number(appVersion) < Number(data.appMinVersion) &&
        setVisible(true);
    }
  }, [loading, data]);

  useEffect(() => {
    appMinVersion();
  }, []);

  return (
    <DialogModal
      visible={visible}
      message={t("general.app-version-modal.message")}
      title={t("general.app-version-modal.title")}
      okText={t("general.app-version-modal.exit")}
      onOk={() => BackHandler.exitApp()}
    />
  );
};

export default AndroidVersionModal;
