import styled from "@emotion/native";
import React, { FC, useState } from "react";
import { Platform } from "react-native";
import colors from "../colors";
import { useBreakpoints } from "../lib/breakpoints";
import Icon from "./Icon";
import Text from "./Text";

export interface IOption {
  text?: string;
  image?: string;
  selected?: boolean;
  right?: boolean;
  wrong?: boolean;
}

export interface IOptionsProps {
  options: IOption[];
  onOptionPress: (option: number) => void;
}

const Options: FC<IOptionsProps> = ({ options, onOptionPress }) => {
  const { isDesktop } = useBreakpoints();
  let optionsPlace: number[][] = [];

  if(options.length === 1){
    optionsPlace = [[0]];
  }else if(options.length === 2){
    optionsPlace = [[0,1]];
  }else if(options.length === 3){
    optionsPlace = [[0,1],[2]];
  }else{
    optionsPlace = [[0,1],[2,3]];
  }

  return (
    <Container>
      {optionsPlace.map((row, i) => (
        <Row key={`row-${i}`} style={{ padding: isDesktop ? 15 : 10 }}>
          {row.length > 0 && row.map((index) => (
            <Option
              key={index}
              style={
                options[index].selected ||
                options[index].right ||
                options[index].wrong
                  ? {
                      borderColor: options[index].selected
                        ? colors.turquoise
                        : options[index].right
                        ? colors.green
                        : colors.red,
                      borderWidth: 4,
                      marginHorizontal: isDesktop ? 13 : 8,
                      marginVertical: -2,
                    }
                  : {
                      borderColor: colors.lightGray,
                      borderWidth: 2,
                      marginHorizontal: isDesktop ? 15 : 10,
                      marginVertical: 0,
                    }
              }
              onPress={() => onOptionPress(index)}
            >
              {options[index].text && (
                <StyledText>{options[index].text}</StyledText>
              )}
              {options[index].image && (
                <Image source={{ uri: options[index].image }} />
              )}
              {(options[index].right || options[index].wrong) && (
                <Circle
                  style={{
                    backgroundColor: options[index].right
                      ? colors.green
                      : colors.red,
                    transform: [
                      { translateX: Platform.OS === "web" ? -15 : 5 },
                    ],
                  }}
                >
                  <CircleIcon
                    name={options[index].right ? "solved" : "close"}
                  />
                </Circle>
              )}
            </Option>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Options;

const Container = styled.View`
  flex: 1;
  align-items: center;
  padding: 50px 0;
`;

const Row = styled.View`
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

const Option = styled.TouchableOpacity`
  align-items: center;
  flex: 1;
  border-radius: 10px;
  justify-content: center;
  padding: 20px;
`;

const Image = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: contain;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const Circle = styled.View`
  position: absolute;
  top: -15px;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
`;

const CircleIcon = styled(Icon)`
  color: white;
  height: 24px;
  width: 24px;
`;
