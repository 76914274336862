import React, { FC, useContext } from "react";
import messages from "../messages";
import { defaultLanguage } from "../config";

type TranslateFn = (id: string, variables?: string[]) => string;

export interface ITranslateContext {
  translateFn: TranslateFn;
  language: string;
}

export const TranslateContext = React.createContext<ITranslateContext>({
  translateFn: (id: string) => id,
  language: defaultLanguage,
});

const findByString = (object: any, selector: string) => {
  if (!selector) {
    return;
  }

  const ids = selector
    .replace(/\[(\w+)\]/g, ".$1")
    .replace(/^\./, "")
    .split(".");

  return ids.reduce((o, id) => {
    if (typeof o === "object" && id in o) {
      return o[id];
    } else {
      return;
    }
  }, object);
};

export const TranslateConsumer = TranslateContext.Consumer;

export const TranslateProvider: FC = ({ children }) => {
  const language = defaultLanguage;
  const langMessages = messages[language];

  const translateFn = (id: string, variables?: string[]) => {
    let translation: string = findByString(langMessages, id);
    if (translation) {
      if (variables) {
        variables.forEach((variable: string) => {
          translation = translation.replace("%v%", variable);
        });
      }
      return translation;
    }
    return id;
  };

  return (
    <TranslateContext.Provider value={{ translateFn, language }}>
      {children}
    </TranslateContext.Provider>
  );
};

export const useTranslate = (): TranslateFn => {
  return useContext(TranslateContext).translateFn;
};

export const useLanguage = (): string => {
  return useContext(TranslateContext).language;
};
