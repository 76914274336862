import styled from "@emotion/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { FC, useEffect, useRef, useState } from "react";
import { Image, Platform, ScrollView, View } from "react-native";
import { Age, ILabelActivityContent } from "../../types";
import colors from "../../colors";
import Icon from "../Icon";
import LabelBalloon from "../LabelBalloon";
import Text from "../Text";
import { DraxProvider, DraxScrollView, DraxView } from "react-native-drax";

export interface ILabelActivityProps {
  content: ILabelActivityContent;
  age?: Age;
  onCompleteChange: (complete: boolean, success: boolean) => void;
}

const LabelActivity: FC<ILabelActivityProps> = ({
  content,
  age,
  onCompleteChange,
}) => {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [draggingLabel, setDraggingLabel] = useState<string>("");
  const [draggingLabelFromBallon, setDraggingLabelFromBallon] = useState<
    string
  >(""); // This state  has the "id" of the ballon when dragging the tag that is assigned to that ballon
  const [draggingOutOfOrigin, setDraggingOutOfOrigin] = useState<boolean>(
    false
  ); //
  const [selectedBalloon, setSelectedBalloon] = useState("");
  const [assignedLabels, setAssignedLabels] = useState<{
    [balloonLabel: string]: string;
  }>({});
  const [labelWidths, setLabelWidths] = useState<{ [label: string]: number }>(
    {}
  );
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [imageOriginalWidth, setImageOriginalWidth] = useState(0);
  const [imageOriginalHeight, setImageOriginalHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const [drawerWidth, setDrawerWidth] = useState(0);
  const [drawerScroll, setDrawerScroll] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const scrollRef = useRef<ScrollView>(null);

  useEffect(() => {
    Image.getSize(
      content.background,
      (width, height) => {
        setImageOriginalWidth(width);
        setImageOriginalHeight(height);
      },
      () => null
    );
  }, [content.background]);

  useEffect(() => {
    if (imageOriginalWidth > 0 && imageOriginalHeight > 0) {
      const widthRatio = canvasWidth / imageOriginalWidth;
      const heightRatio = canvasHeight / imageOriginalHeight;

      const ratio = Math.min(widthRatio, heightRatio);
      setImageWidth(imageOriginalWidth * ratio);
      setImageHeight(imageOriginalHeight * ratio);
    }
  }, [canvasHeight, canvasWidth, imageOriginalHeight, imageOriginalWidth]);

  useEffect(() => {
    setCanScrollLeft(drawerScroll > 0);
    setCanScrollRight(drawerWidth - scrollWidth - drawerScroll > 1);
  }, [drawerWidth, drawerScroll, scrollWidth]);

  const onBalloonPress = (ballonLabel: string) => {
    if (assignedLabels[ballonLabel]) {
      setAssignedLabels({
        ...assignedLabels,
        [ballonLabel]: "",
      });
    } else if (ballonLabel === selectedBalloon) {
      setSelectedBalloon("");
    } else if (selectedLabel) {
      setAssignedLabels({
        ...assignedLabels,
        [ballonLabel]: selectedLabel,
      });
      setSelectedLabel("");
      setSelectedBalloon("");
    } else {
      setSelectedBalloon(ballonLabel);
    }
  };

  const onLabelPress = (label: string) => {
    if (selectedBalloon) {
      setAssignedLabels({
        ...assignedLabels,
        [selectedBalloon]: label,
      });
      setSelectedLabel("");
      setSelectedBalloon("");
    } else if (label === selectedLabel) {
      setSelectedLabel("");
    } else {
      setSelectedLabel(label);
    }
  };

  const onLabelDrop = (ballonText: string, labelText: string) => {
    if (ballonText && labelText && draggingLabelFromBallon) {
      setAssignedLabels({
        ...assignedLabels,
        [draggingLabelFromBallon]: "",
        [ballonText]: labelText,
      });
    } else if (ballonText && labelText) {
      setAssignedLabels({ ...assignedLabels, [ballonText]: labelText });
    }
  };

  const onFreeBalloon = (ballonText: string) => {
    if (draggingOutOfOrigin) {
      setAssignedLabels({ ...assignedLabels, [ballonText]: "" });
    }
  };

  const usedLabels = Object.values(assignedLabels).filter((l) => l);

  useEffect(() => {
    const success = content.labels.every(
      (label) =>
        assignedLabels[label.text] === label.text ||
        (label.alsoValidIn &&
          assignedLabels[content.labels[label.alsoValidIn - 1].text] ===
          label.text)
    );
    onCompleteChange(usedLabels.length === content.labels.length, success);
  }, [usedLabels]);

  const remainingLabels = [...content.labels, ...content.incorrectLabels]
    .filter((label) => !usedLabels.includes(label.text))
    .sort((a, b) => (a.drawerPosition >= b.drawerPosition ? 1 : -1));

  const renderScrollViewContent = () => {
    return (
      <DrawerLabels
        onLayout={(e) => setDrawerWidth(e.nativeEvent.layout.width)}
      >
        {remainingLabels.map((label, index) => (
          <DraxView
            key={index + drawerScroll}
            dragPayload={label.text}
            longPressDelay={100}
            onDragStart={() => setDraggingLabel(label.text)}
            onDragEnd={() => setDraggingLabel("")}
            onDragDrop={() => setDraggingLabel("")}
            draggingStyle={{ opacity: 0 }}
            hoverDragReleasedStyle={{ opacity: 0 }}
            draggable={age === Age.nineToTwelve}
            style={{ marginTop: Platform.OS === "web" ? 0 : 8 }} // This is to hide the empty space left by the invisible scroll in no-web devices
          >
            <DrawerLabel
              key={label.text}
              onPress={() => onLabelPress(label.text)}
              onLayout={(e) =>
                setLabelWidths({
                  ...labelWidths,
                  [label.text]: e.nativeEvent.layout.width,
                })
              }
              style={{
                backgroundColor:
                  label.text === selectedLabel || label.text === draggingLabel
                    ? colors.turquoise
                    : colors.white,
              }}
              activeOpacity={1}
            >
              <LabelText
                style={{
                  color:
                    label.text === selectedLabel ? colors.white : colors.black,
                  width: label.text === draggingLabel ? "101%" : "100%",
                }}
              >
                {label.text}
              </LabelText>
            </DrawerLabel>
          </DraxView>
        ))}
      </DrawerLabels>
    );
  };

  return (
    <Container>
      <DraxProvider>
        <Canvas
          onLayout={(e) => {
            const { width, height } = e.nativeEvent.layout;
            setCanvasHeight(height);
            setCanvasWidth(width);
          }}
        >
          <Background
            source={{ uri: content.background }}
            style={{ width: imageWidth, height: imageHeight }}
            onLoadEnd={() => setImageLoaded(true)}
          />
          <Labels style={{ width: imageWidth, height: imageHeight }}>
            {imageLoaded &&
              content.labels.map((label) => {
                return (
                  <LabelBalloon
                    key={label.text}
                    id={label.text}
                    anchor={label.anchor}
                    left={label.x * imageWidth}
                    top={label.y * imageHeight}
                    text={assignedLabels[label.text]}
                    onPress={() => onBalloonPress(label.text)}
                    onDrop={(text) => onLabelDrop(label.text, text)}
                    width={labelWidths[assignedLabels[label.text]] < 270 ? labelWidths[assignedLabels[label.text]] : 270}
                    selected={selectedBalloon === label.text}
                    draggable={age === Age.nineToTwelve}
                    setDraggingLabel={setDraggingLabel}
                    onFreeBalloon={onFreeBalloon}
                    setDraggingLabelFromBallon={setDraggingLabelFromBallon}
                    setDraggingOutOfOrigin={setDraggingOutOfOrigin}
                  />
                )
              })}
          </Labels>
        </Canvas>
        <Drawer>
          {!canScrollLeft && !canScrollRight ? (
            <EmptySpace />
          ) : (
            <ScrollButton
              onPress={() =>
                canScrollLeft &&
                scrollRef.current?.scrollTo({
                  x: drawerScroll - 100,
                  animated: true,
                })
              }
              activeOpacity={canScrollLeft ? 0.3 : 1}
              hidden={!canScrollLeft && !canScrollRight}
            >
              <ScrollLeftIcon
                name="angle"
                style={{ opacity: canScrollLeft ? 1 : 0.5 }}
              />
            </ScrollButton>
          )}
          {Platform.OS === "web" ? (
            <StyledScrollView
              horizontal
              ref={scrollRef}
              onScroll={(e) => setDrawerScroll(e.nativeEvent.contentOffset.x)}
              onLayout={(e) => setScrollWidth(e.nativeEvent.layout.width)}
            >
              {renderScrollViewContent()}
            </StyledScrollView>
          ) : (
            <StyledDaxScrollView
              horizontal
              ref={scrollRef}
              onScroll={(e) => setDrawerScroll(e.nativeEvent.contentOffset.x)}
              onLayout={(e) => setScrollWidth(e.nativeEvent.layout.width)}
            >
              {renderScrollViewContent()}
            </StyledDaxScrollView>
          )}
          {!canScrollLeft && !canScrollRight ? (
            <EmptySpace />
          ) : (
            <ScrollButton
              onPress={() =>
                canScrollRight &&
                scrollRef.current?.scrollTo({
                  x: drawerScroll + 100,
                  animated: true,
                })
              }
              activeOpacity={canScrollRight ? 0.3 : 1}
              hidden={!canScrollLeft && !canScrollRight}
            >
              <ScrollRightIcon
                name="angle"
                style={{ opacity: canScrollRight ? 1 : 0.5 }}
              />
            </ScrollButton>
          )}
          <Gradient
            colors={[`${colors.black}FF`, `${colors.black}00`]}
            start={[0, 0]}
            end={[1, 0]}
            style={{ left: 60 }}
          />
          <Gradient
            colors={[`${colors.black}00`, `${colors.black}FF`]}
            start={[0, 0]}
            end={[1, 0]}
            style={{ right: 60 }}
          />
        </Drawer>
      </DraxProvider>
    </Container>
  );
};

export default LabelActivity;

const Container = styled.View`
  flex: 1;
`;

const Canvas = styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Background = styled.Image`
  position: absolute;
`;

const Labels = styled.View`
  position: absolute;
`;

const Drawer = styled.View`
  height: 80px;
  background-color: ${colors.black};
  flex-direction: row;
  align-items: center;
  overflow: hidden;
`;

const ScrollButton = styled.TouchableOpacity`
  padding: 0 10px;
`;

const Gradient = styled(LinearGradient)`
  width: 10px;
  position: absolute;
  height: 80px;
`;

const ScrollLeftIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
  color: ${colors.white};
`;

const ScrollRightIcon = styled(ScrollLeftIcon)`
  transform: rotate(-90deg);
`;

const StyledScrollView = styled(ScrollView)`
  overflow-x: hidden;
  flex: 1;
`;

const StyledDaxScrollView = styled(DraxScrollView)`
  flex: 1;
`;

const DrawerLabels = styled.View`
  flex: 1;
  flex-direction: row;
  padding: 0 5px;
`;

const DrawerLabel = styled.TouchableOpacity`
  height: 64px;
  border-radius: 10px;
  background-color: ${colors.white};
  padding: 0 24px;
  justify-content: center;
  margin: 0 5px;
`;

const LabelText = styled(Text)`
  font-size: 18px;
`;

const EmptySpace = styled.View`
  width: 60px;
  height: 0px;
`;
